import Vue from 'vue';
import Vuex from 'vuex';
import SectionTypes from '../models/SectionTypesEnum'
import * as Section from '../models/Section'

Vue.use(Vuex);

export default new Vuex.Store({
    
    state: {
        isLoading: false,
        reservationMessage: '',
        currentSectionIndex: 0,
        cancelReservation: false,
        showConceptSection: 0, // possible values, 0(don't show), 1(show)
        showAreaSection: 0, // possible values, 0(don't show), 1(show after select people), 2(show after selecting data/time)
        showNumberOfChildrenSection: false,
        showNumberOfChildrenChairSection: false,
        newSectionsData: [],
        isConceptSelected: false,

        // Language stuff
        lang: {
            name: '',
            default: {},
            company: {}
        }
    },

    getters: {
        getSectionTypeAtIndex: (state) => (index) => {
            if (index >= state.newSectionsData.length) {
                return undefined
            }
            return state.newSectionsData[index].id
        },
        isConceptSelected(state) {
            let selectConceptSection = state.newSectionsData.find(section => section.id===SectionTypes.SELECT_CONCEPT)
            if(selectConceptSection !== undefined) {
                return selectConceptSection.isConceptSelected
            } else {
                return false
            } 
        },
        isLastSection(state) {
            return state.currentSectionIndex == (state.newSectionsData.length - 1)
        }
    },

    mutations: {
        updateLang(state, {lang}) {
            this.lang = lang
            state.newSectionsData.forEach(section => section.updateLang({lang: lang}))
        },
        setMainSections(state) {
            state.currentSectionIndex = 0
            if (state.cancelReservation) {
                this.state.newSectionsData = [
                    new Section.BookingInformationSection({lang: state.lang}),
                    new Section.CancellationCompleSection({lang: state.lang})
                ]
            }else {
                this.state.newSectionsData = [
                    new Section.SelectConceptSection({lang: state.lang}),
                    new Section.SelectPeopleSection({lang: state.lang}),
                    new Section.SelectDateSection({lang: state.lang}),
                    new Section.ConfirmDateSection({lang: state.lang}),
                    new Section.SelectTimeSection({lang: state.lang}),
                    new Section.ConfirmTimeSection({lang: state.lang}),
                    new Section.SelectSpecialBookingOptionsSection({lang: state.lang}),
                    new Section.ContactInformationSection({lang: state.lang}),
                    new Section.BookingCompleteSection({lang: state.lang})
                ]
            }
        },
        updateSections(state) {
            function updateConceptsSection() {
                if (state.showConceptSection == 0) {
                    let newArray = state.newSectionsData.filter(section => section.id!==SectionTypes.SELECT_CONCEPT)
                    state.newSectionsData = newArray
                    return
                }
            }
            function updateSelectNumberOfChildrenSection() {
                if (state.showNumberOfChildrenSection == false) {
                    let newArray = state.newSectionsData.filter(section => section.id!==SectionTypes.SELECT_CHILDREN)
                    state.newSectionsData = newArray
                    return
                }

                let selectPeopleSection = state.newSectionsData.find(section=>section.id===SectionTypes.SELECT_PEOPLE)
                if (selectPeopleSection === undefined) {
                    let newArray = state.newSectionsData.filter(section => section.id!==SectionTypes.SELECT_CHILDREN)
                    state.newSectionsData = newArray
                    return
                }

                if (!selectPeopleSection.selectedData.hasOwnProperty('numberOfPeople') || // user did not select number of people yet
                    selectPeopleSection.selectedData.numberOfPeople > 1
                ) {
                    let selectNumberOfPeopleSectionIndex = state.newSectionsData.findIndex(section=>section.id===SectionTypes.SELECT_PEOPLE)
                    let selectChildrenSectionExists = state.newSectionsData.find(section=>section.id===SectionTypes.SELECT_CHILDREN) !== undefined
                    if (!selectChildrenSectionExists) {
                        state.newSectionsData.splice(selectNumberOfPeopleSectionIndex+1, 0, new Section.SelectChildrenSection({lang: state.lang}))
                    }

                }else {
                    let newArray = state.newSectionsData.filter(section => section.id!==SectionTypes.SELECT_CHILDREN)
                    state.newSectionsData = newArray
                }
            }
            function updateSelectNumberOfChildrenChairsSection() {
                if (state.showNumberOfChildrenChairSection == false) {
                    let newArray = state.newSectionsData.filter(section => section.id!==SectionTypes.SELECT_CHILDREN_CHAIRS)
                    state.newSectionsData = newArray
                    return
                }

                let selectChildrenSection = state.newSectionsData.find(section=>section.id===SectionTypes.SELECT_CHILDREN)
                if (!selectChildrenSection) {
                    let newArray = state.newSectionsData.filter(section => section.id!==SectionTypes.SELECT_CHILDREN_CHAIRS)
                    state.newSectionsData = newArray
                    return
                }

                if (!selectChildrenSection.selectedData.hasOwnProperty('numberOfChildren') || // user did not select number of chairs yet
                    selectChildrenSection.selectedData.numberOfChildren > 0
                ) {
                    let selectChildrenChairsSectionExists = state.newSectionsData.find(section=>section.id===SectionTypes.SELECT_CHILDREN_CHAIRS) !== undefined
                    let selectChildrenIndex = state.newSectionsData.findIndex(section=>section.id===SectionTypes.SELECT_CHILDREN)
                    if (!selectChildrenChairsSectionExists) {
                        state.newSectionsData.splice(selectChildrenIndex+1, 0, new Section.SelectChildrenChairsSection({lang: state.lang}))
                    }
                }else {
                    let newArray = state.newSectionsData.filter(section => section.id!==SectionTypes.SELECT_CHILDREN_CHAIRS)
                    state.newSectionsData = newArray
                    return
                }
            }
            function updateAllowSelectAreaSection() {

                if (state.showAreaSection == 0) {
                    let newArray = state.newSectionsData.filter(
                        section => section.id!==SectionTypes.SELECT_AREA && section.id!==SectionTypes.SELECT_AREA_AFTER_TIME
                    )
                    state.newSectionsData = newArray
                    return
                }


                if (state.showAreaSection == 1) {

                    var indexToInsertIn = -1
                    let selectChildrenChairsSectionIndex = state.newSectionsData.findIndex(section=>section.id===SectionTypes.SELECT_CHILDREN_CHAIRS)
                    let selectChildrenSectionIndex = state.newSectionsData.findIndex(section=>section.id===SectionTypes.SELECT_CHILDREN)
                    let selectPeopleSectionIndex = state.newSectionsData.findIndex(section=>section.id===SectionTypes.SELECT_PEOPLE)

                    if (selectChildrenChairsSectionIndex != -1) {
                        indexToInsertIn = selectChildrenChairsSectionIndex + 1
                    }else if (selectChildrenSectionIndex != -1) {
                        indexToInsertIn = selectChildrenSectionIndex + 1
                    }else if (selectPeopleSectionIndex != -1){
                        indexToInsertIn = selectPeopleSectionIndex + 1
                    }

                    if (indexToInsertIn == -1) {
                        let selectAreaSection = state.newSectionsData.filter(section => section.id===SectionTypes.SELECT_AREA)
                        if (selectAreaSection !== undefined) {
                            let newArray = state.newSectionsData.filter(section => section.id!==SectionTypes.SELECT_AREA)
                            state.newSectionsData = newArray
                        }
                        return
                    }

                    let sectionExists = state.newSectionsData.find(section=>section.id===SectionTypes.SELECT_AREA) !== undefined
                    if (!sectionExists) {
                        state.newSectionsData.splice(indexToInsertIn, 0, new Section.SelectAreaSection({lang: state.lang}))
                    }
                }else if (state.showAreaSection == 2) {
                    let selectTimeSection = state.newSectionsData.find(section=>section.id===SectionTypes.SELECT_TIME)
                    if (selectTimeSection === undefined) {
                        let selectAreaSection = state.newSectionsData.find(section=>section.id===SectionTypes.SELECT_AREA_AFTER_TIME)
                        if (selectAreaSection !== undefined) {
                            let newArray = state.newSectionsData.filter(section => section.id!==SectionTypes.SELECT_AREA_AFTER_TIME)
                            state.newSectionsData = newArray  
                        }
                        return
                    }
                    var indexToInsertIn = -1
                    let confirmTimeSectionIndex = state.newSectionsData.findIndex(section=>section.id===SectionTypes.CONFIRM_TIME)
                    let selectTimeSectionIndex = state.newSectionsData.findIndex(section=>section.id===SectionTypes.SELECT_TIME)
                    if (confirmTimeSectionIndex != -1) {
                        indexToInsertIn = confirmTimeSectionIndex
                    } else if (selectTimeSectionIndex != -1) {
                        indexToInsertIn = selectTimeSectionIndex
                    }
                    if (indexToInsertIn == -1) {
                        let selectAreaSection = state.newSectionsData.find(section=>section.id===SectionTypes.SELECT_AREA_AFTER_TIME)
                        if (selectAreaSection !== undefined) {
                            let newArray = state.newSectionsData.filter(section => section.id!==SectionTypes.SELECT_AREA_AFTER_TIME)
                            state.newSectionsData = newArray
                        }
                        return 
                    }

                    let sectionExists = state.newSectionsData.find(section=>section.id===SectionTypes.SELECT_AREA_AFTER_TIME) !== undefined
                    if (!sectionExists) {
                        state.newSectionsData.splice(indexToInsertIn+1, 0, new Section.SelectAreaAfterTimeSection({lang: state.lang}))
                    }

                    let confirmAreaSection = state.newSectionsData.find(section=>section.id===SectionTypes.CONFIRM_AREA)
                    let confirmAreaSectionExists = confirmAreaSection !== undefined
                    if (confirmAreaSectionExists) {
                        let hasNoContent = confirmAreaSection.selectedData.hasOwnProperty('noContent')
                        if (hasNoContent) {
                            let newArray = state.newSectionsData.filter(section => section.id!==SectionTypes.CONFIRM_AREA)
                            state.newSectionsData = newArray
                            state.currentSectionIndex--
                        }
                    } else {
                        let selectAreaAfterTimeSectionIndex = state.newSectionsData.findIndex(section=>section.id===SectionTypes.SELECT_AREA_AFTER_TIME)
                        if (state.currentSectionIndex <= selectAreaAfterTimeSectionIndex) {
                            state.newSectionsData.splice(indexToInsertIn+2, 0, new Section.ConfirmAreaSection({lang: state.lang}))
                        }                        
                    }
                }
            }
            function updateSpecialBookingOptionsSection() {
                let selectBookingOptionsSection = state.newSectionsData.find(section=>section.id===SectionTypes.SELECT_SPECIAL_BOOKING_OPTIONS)
                
                if (selectBookingOptionsSection === undefined) {
                    const contactInformationSectionIndex = state.newSectionsData.findIndex(section=>section.id===SectionTypes.CONTACT_INFORMATION)
                    if (state.currentSectionIndex < contactInformationSectionIndex) {
                        state.newSectionsData.splice(contactInformationSectionIndex, 0, new Section.SelectSpecialBookingOptionsSection({lang: state.lang}))
                    }
                } else {
                    const hasNoContent = selectBookingOptionsSection.selectedData.hasOwnProperty('noContent')
                    if (hasNoContent) {
                        let newArray = state.newSectionsData.filter(section => section.id!==SectionTypes.SELECT_SPECIAL_BOOKING_OPTIONS)
                        state.newSectionsData = newArray
                        state.currentSectionIndex--
                    }
                }
            }
            
            if (!state.cancelReservation) {
                updateConceptsSection()
                updateSelectNumberOfChildrenSection()
                updateSelectNumberOfChildrenChairsSection()
                updateAllowSelectAreaSection()
                updateSpecialBookingOptionsSection()
            }
            
        },
        changedSelectedIndex(state, {index, apiService} ) {
            if (index >= state.currentSectionIndex) { 
                return
            }

            state.currentSectionIndex = index

            let sectionType = state.newSectionsData[index].id

            // reset all sections after selected section
            const sectionsAfterSelectedSection = state.newSectionsData.slice(index+1)
            sectionsAfterSelectedSection.forEach((section) => section.reset())            

            this.dispatch(`request-${sectionType}`, apiService)
        },
        incremenetCurrentSectionIndex(state) {
            state.currentSectionIndex++
        },
        setDataToSection(state, {sectionID, data}) {
            function handleSelectDate() {
                let selectDateSection = state.newSectionsData.find(section => section.id===SectionTypes.SELECT_DATE)
                if (selectDateSection.selectedData.comment == '') {
                    // Deleting confirm date section if exists
                    let newArray = state.newSectionsData.filter(section => section.id!==SectionTypes.CONFIRM_DATE)
                    state.newSectionsData = newArray
    
                }else {
                    let confirmDateSection = state.newSectionsData.find(section => section.id===SectionTypes.CONFIRM_DATE)
                    if (confirmDateSection === undefined) {
                        state.newSectionsData.splice(state.currentSectionIndex + 1, 0, new Section.ConfirmDateSection({lang: state.lang}))
                    }
                }
            }
            function handleSelectTime() {
                let selectTimeSection = state.newSectionsData.find(section=>section.id===SectionTypes.SELECT_TIME)
                if (selectTimeSection.selectedData.message == '') {
                    // Delete confirm time if exists
                    let newArray = state.newSectionsData.filter(section => section.id!==SectionTypes.CONFIRM_TIME)
                    state.newSectionsData = newArray
    
                }else {
                    let confirmTimeSection = state.newSectionsData.find(section=>section.id===SectionTypes.CONFIRM_TIME)
                    if (confirmTimeSection === undefined) {
                        let confirmTimeSection = new Section.ConfirmTimeSection({lang: state.lang})
                        confirmTimeSection.parseDataFromServer({data: {
                            'message': selectTimeSection.selectedData.message
                        }})
                        state.newSectionsData.splice(state.currentSectionIndex + 1, 0, new Section.ConfirmTimeSection({lang: state.lang}))
                    }
                        
                }
            }

            let section = state.newSectionsData.find(section => section.id===sectionID)
            let success = section.setUserSelectedData({data:data})
            
            if (sectionID === SectionTypes.SELECT_DATE) {
                handleSelectDate()
            }else if (sectionID === SectionTypes.SELECT_TIME) {
                handleSelectTime()
            }
        },
    },
    actions: {
        ['request-'+SectionTypes.SELECT_CONCEPT](state, apiService) {
            let section = this.state.newSectionsData.find(section => section.id===SectionTypes.SELECT_CONCEPT)

            this.state.isLoading = true
            section.reset()

            apiService.getConceptsAndEvents({lang:this.state.lang.name})
                .then((data) => {
                    this.state.isLoading = false
                    if(data.length > 0){
                        section.parseDataFromServer({data:data})
                    }
                })
                .catch((err) => { console.log(err); this.state.isLoading = false })
        },
        ['request-'+SectionTypes.SELECT_PEOPLE](state, apiService) {
            let section = this.state.newSectionsData.find(section => section.id===SectionTypes.SELECT_PEOPLE)

            this.state.isLoading = true
            section.reset()


           // let concept = selectConceptSection.selectedData.id

            apiService.getPersons({lang:this.state.lang.name})
                .then((data) => {
                    this.state.isLoading = false
                    section.parseDataFromServer({data:data})
                })
                .catch((err) => { console.log(err); this.state.isLoading = false })
        },
        ['request-'+SectionTypes.SELECT_CHILDREN](state, apiService) {
            let section = this.state.newSectionsData.find(section => section.id===SectionTypes.SELECT_CHILDREN)

            this.state.isLoading = true
            section.reset()

            let selectPeopleSection = this.state.newSectionsData.find(section => section.id===SectionTypes.SELECT_PEOPLE)
            let numberOfPeople = selectPeopleSection.selectedData.numberOfPeople

            apiService.getChilds({persons:numberOfPeople, lang:this.state.lang.name})
                .then((data) => {
                    this.state.isLoading = false
                    section.parseDataFromServer({data:data})
                })
                .catch((err) => { console.log(err); this.state.isLoading = false })
        },
        ['request-'+SectionTypes.SELECT_CHILDREN_CHAIRS](state, apiService) {
            let section = this.state.newSectionsData.find(section => section.id===SectionTypes.SELECT_CHILDREN_CHAIRS)
        
            this.state.isLoading = true
            section.reset()

            let selectChildrenSection = this.state.newSectionsData.find(section => section.id===SectionTypes.SELECT_CHILDREN)
            let numberOfChildren = selectChildrenSection.selectedData.numberOfChildren

            apiService.getChildChairs({childs:numberOfChildren, lang:this.state.lang.name})
                .then((data) => {
                    this.state.isLoading = false
                    section.parseDataFromServer({data:data})
                })
                .catch((err) => { console.log(err); this.state.isLoading = false })
        },
        ['request-'+SectionTypes.SELECT_DATE](state, apiService) {
            let section = this.state.newSectionsData.find(section => section.id===SectionTypes.SELECT_DATE)

            this.state.isLoading = true
            section.reset()

            let concept_id = 0;
            let selectConceptSection = this.state.newSectionsData.find(section => section.id===SectionTypes.SELECT_CONCEPT)
            if(selectConceptSection !== undefined){
                concept_id = selectConceptSection.selectedData.concept_id;
            }

            let selectPeopleSection = this.state.newSectionsData.find(section => section.id===SectionTypes.SELECT_PEOPLE)
            let numberOfPeople = selectPeopleSection.selectedData.numberOfPeople

            var area = null
            let selectAreaSection = this.state.newSectionsData.find(section => section.id===SectionTypes.SELECT_AREA)
            if (selectAreaSection !== undefined) {
                area = selectAreaSection.selectedData.area
            }

            let numberOfChildren = null
            let selectNumberOfChildsSection = this.state.newSectionsData.find(section => section.id===SectionTypes.SELECT_CHILDREN)
            if (selectNumberOfChildsSection !== undefined) {
                numberOfChildren = selectNumberOfChildsSection.selectedData.numberOfChildren
            }

            let params = {
                persons: numberOfPeople,
                area:area,
                numberOfChildren:numberOfChildren,
                lang: this.state.lang.name,
                concept: concept_id
            }
            apiService.getDates(params)
                .then((data) => {
                    this.state.isLoading = false
                    section.parseDataFromServer({data:data})
                })
                .catch((err) => { console.log(err); this.state.isLoading = false })
        },
        ['request-'+SectionTypes.CONFIRM_DATE](state, apiService){
            let section = this.state.newSectionsData.find(section => section.id===SectionTypes.CONFIRM_DATE)
            section.reset()

            let selectDateSection = this.state.newSectionsData.find(section => section.id===SectionTypes.SELECT_DATE)
            if (selectDateSection !== undefined) {
                const comment = selectDateSection.selectedData.comment
                const remove_continue_btn = selectDateSection.selectedData.remove_continue_btn
                section.parseDataFromServer({
                    data: {
                        'remove_continue_btn': remove_continue_btn,
                        'comment': comment
                    }
                })
            }
        },
        ['request-'+SectionTypes.SELECT_TIME](state, apiService) {
            let section = this.state.newSectionsData.find(section => section.id===SectionTypes.SELECT_TIME)

            this.state.isLoading = true
            section.reset()

            let concept_id = 0;
            let selectConceptSection = this.state.newSectionsData.find(section => section.id===SectionTypes.SELECT_CONCEPT)
            if(selectConceptSection !== undefined){
                concept_id = selectConceptSection.selectedData.concept_id;
            }

            let selectPeopleSection = this.state.newSectionsData.find(section=>section.id===SectionTypes.SELECT_PEOPLE)
            let numberOfPeople = selectPeopleSection.selectedData.numberOfPeople

            let selectDateSection = this.state.newSectionsData.find(section=>section.id===SectionTypes.SELECT_DATE)
            let date = selectDateSection.selectedData.date
            
            let area = null
            let selectAreaSection = this.state.newSectionsData.find(section=>section.id===SectionTypes.SELECT_AREA)
            if (selectAreaSection !== undefined) {
                area = selectAreaSection.selectedData.area
            }

            let numberOfChildren = null
            let selectNumberOfChildrenSection = this.state.newSectionsData.find(section=>section.id===SectionTypes.SELECT_CHILDREN)
            if (selectNumberOfChildrenSection !== undefined) {
                numberOfChildren = selectNumberOfChildrenSection.selectedData.numberOfChildren
            }

            let params = {
                concept: concept_id,
                persons: numberOfPeople,
                date: date,
                area: area,
                numberOfChildren: numberOfChildren,
                lang:this.state.lang.name
            }
            apiService.getTimes(params)
                .then((data) => {
                    this.state.isLoading = false
                    section.parseDataFromServer({data:data})
                })
                .catch((err) => { console.log(err); this.state.isLoading = false })
        },
        ['request-'+SectionTypes.CONFIRM_TIME](state, apiService){
            let section = this.state.newSectionsData.find(section => section.id===SectionTypes.CONFIRM_TIME)
            section.reset()

            let selectTimeSection = this.state.newSectionsData.find(section => section.id===SectionTypes.SELECT_TIME)
            if (selectTimeSection !== undefined) {
                const availableLengths = selectTimeSection.selectedData.availableLengths
                const message = selectTimeSection.selectedData.message
                const time = selectTimeSection.selectedData.time
                section.parseDataFromServer({
                    data: {
                        'availableLengths': availableLengths,
                        'message': message,
                        'time': time
                    }
                })
            }
        },
        ['request-'+SectionTypes.SELECT_AREA](state, apiService) {
            let section = this.state.newSectionsData.find(section => section.id===SectionTypes.SELECT_AREA)

            this.state.isLoading = true
            section.reset()

            apiService.getAreas({lang: this.state.lang.name})
                .then((data) => {
                    this.state.isLoading = false
                    section.parseDataFromServer({data:data})
                })
                .catch((err) => { console.log(err); this.state.isLoading = false })
        },
        ['request-'+SectionTypes.SELECT_AREA_AFTER_TIME](state, apiService) {
            let section = this.state.newSectionsData.find(section => section.id===SectionTypes.SELECT_AREA_AFTER_TIME)

            this.state.isLoading = true
            section.reset()

            let selectPeopleSection = this.state.newSectionsData.find(section => section.id===SectionTypes.SELECT_PEOPLE)
            let numberOfPeople = selectPeopleSection.selectedData.numberOfPeople

            let selectDateSection = this.state.newSectionsData.find(section => section.id===SectionTypes.SELECT_DATE)
            let date = selectDateSection.selectedData.date

            let selectTimeSection = this.state.newSectionsData.find(section => section.id===SectionTypes.SELECT_TIME)
            let time = selectTimeSection.selectedData.time

            let numberOfChildren = null
            let selectChildSection = this.state.newSectionsData.find(section => section.id===SectionTypes.SELECT_CHILDREN)
            if (selectChildSection !== undefined) {
                numberOfChildren = selectChildSection.selectedData.numberOfChildren
            }
            
            let params = {
                persons: numberOfPeople,
                date: date,
                time: time,
                numberOfChildren: numberOfChildren,
                lang: this.state.lang.name
            }
            apiService.getAreasAfterTime(params)
                .then((data) => {
                    this.state.isLoading = false
                    section.parseDataFromServer({data:data})
                })
                .catch((err) => { console.log(err); this.state.isLoading = false })
        },
        ['request-'+SectionTypes.CONFIRM_AREA](state, apiService) {
            let section = this.state.newSectionsData.find(section => section.id===SectionTypes.CONFIRM_AREA)
            section.reset()

            let selectAreaSection = this.state.newSectionsData.find(section => section.id===SectionTypes.SELECT_AREA_AFTER_TIME)
            if (selectAreaSection.selectedData.hasOwnProperty('area_confirm_text')) {
                let areaConfirmText = selectAreaSection.selectedData.area_confirm_text
                if (areaConfirmText === '') {
                    section.parseDataFromServer({data: {}})
                } else {
                    section.parseDataFromServer({
                        data: {'confirmAreaHTML': selectAreaSection.selectedData.area_confirm_text}
                    })
                }
                
            }            
        },
        ['request-'+SectionTypes.SELECT_SPECIAL_BOOKING_OPTIONS](state, apiService) {
            let section = this.state.newSectionsData.find(section => section.id===SectionTypes.SELECT_SPECIAL_BOOKING_OPTIONS)

            this.state.isLoading = true
            section.reset()

            let selectDateSection = this.state.newSectionsData.find(section=>section.id===SectionTypes.SELECT_DATE)
            const date = selectDateSection.selectedData.date

            let selectTimeSection = this.state.newSectionsData.find(section=>section.id===SectionTypes.SELECT_TIME)
            const time = selectTimeSection.selectedData.time

            let params = {
                date: date,
                time: time,
                lang: this.state.lang.name
            }
            return apiService.getOptions(params)
                .then((data) => {
                    this.state.isLoading = false
                    section.parseDataFromServer({data:data})
                })
                .catch((err) => { console.log(err); this.state.isLoading = false })
        },
        ['request-'+SectionTypes.CONTACT_INFORMATION](state, apiService) {
            let section = this.state.newSectionsData.find(section => section.id===SectionTypes.CONTACT_INFORMATION)
            section.reset()
        },
        ['request-'+SectionTypes.BOOKING_COMPLETE](state, apiService) {
            let section = this.state.newSectionsData.find(section => section.id===SectionTypes.BOOKING_COMPLETE)
            section.reset()


            let contactInformationSection = this.state.newSectionsData.find(section => section.id===SectionTypes.CONTACT_INFORMATION)

            const data = { "bookingID": contactInformationSection.data.bookingID }
            section.parseDataFromServer({data})
        },
        ['request-'+SectionTypes.BOOKING_INFORMATION](state, apiService) {
            let section = this.state.newSectionsData.find(section => section.id===SectionTypes.BOOKING_INFORMATION)
            section.reset()
        },
        ['request-'+SectionTypes.CANCELLATION_COMPLETED](state, apiService) {
            let section = this.state.newSectionsData.find(section => section.id===SectionTypes.CANCELLATION_COMPLETED)
            section.reset()
        },
        dataValidator(state, apiService) {
            function validateBookingInformationsData(state, apiService) {
                state.isLoading = true
                const bookingInformationSection = state.newSectionsData.find(section => SectionTypes.BOOKING_INFORMATION)
                const bookingNumber = bookingInformationSection.selectedData.bookingNumber
                const mobileNumber = bookingInformationSection.selectedData.mobileNumber
                const lang = state.lang.name

                return apiService.deleteReservation(bookingNumber, mobileNumber, lang)
                    .then((data) => {
                        state.isLoading = false
                        const result = bookingInformationSection.validateData({data})
                        return result
                    })
                    .catch((err) => { 
                        console.log(err);
                        state.isLoading = false
                        return {'isValid': false, 'error': err}; 
                    })
            }

            function submitBookingInformation(state, apiService) {
                state.isLoading = true
            
                let params = {
                    'lang': state.lang.name
                }

                let selectConceptSection = state.newSectionsData.find(section => section.id===SectionTypes.SELECT_CONCEPT)
                if(selectConceptSection !== undefined){
                    params['concept'] = selectConceptSection.selectedData.concept_id;
                }
                let selectPeopleSection = state.newSectionsData.find(section => section.id===SectionTypes.SELECT_PEOPLE)
                params['persons'] = selectPeopleSection.selectedData.numberOfPeople
                
                let selectDateSection = state.newSectionsData.find(section => section.id===SectionTypes.SELECT_DATE)
                let selectedDate = selectDateSection.selectedData.date

                let selectTimeSection = state.newSectionsData.find(section => section.id===SectionTypes.SELECT_TIME)
                let selectedTime = selectTimeSection.selectedData.time
                params['time'] = selectedDate + ' ' + selectedTime + ':00'

                let contactInformationSection = state.newSectionsData.find(section => section.id===SectionTypes.CONTACT_INFORMATION)
                params['name'] = encodeURIComponent(contactInformationSection.selectedData.name.trim())
                params['email'] = encodeURIComponent(contactInformationSection.selectedData.email.trim())
                params['phone'] = contactInformationSection.selectedData.mobile.trim()
                params['marketing_email'] = +contactInformationSection.selectedData.sendEmail
                params['marketing_sms'] = +contactInformationSection.selectedData.sendSms

                let comment = contactInformationSection.selectedData.comment.trim()
                if (comment !== '') {
                    params['comment'] = comment
                }

                let selectAreaSection = state.newSectionsData.find(section => section.id===SectionTypes.SELECT_AREA)
                if (selectAreaSection !== undefined) {
                    params['area'] = encodeURIComponent(selectAreaSection.selectedData.area)
                }else {
                    let selectAreaAfterTimeSection = state.newSectionsData.find(section => section.id===SectionTypes.SELECT_AREA_AFTER_TIME)
                    if (selectAreaAfterTimeSection !== undefined) {
                        params['area'] = encodeURIComponent(selectAreaAfterTimeSection.selectedData.area)
                    }
                }

                let selectChildrenSection = state.newSectionsData.find(section => section.id===SectionTypes.SELECT_CHILDREN)
                if (selectChildrenSection !== undefined) {
                    params['childs'] = selectChildrenSection.selectedData.numberOfChildren
                }

                let selectChildChairsSection = state.newSectionsData.find(section => section.id===SectionTypes.SELECT_CHILDREN_CHAIRS)
                if (selectChildChairsSection !== undefined) {
                    params['childchairs'] = selectChildChairsSection.selectedData.numberOfChildrenChairs
                }


                let confirmTimeSection = state.newSectionsData.find(section => section.id===SectionTypes.CONFIRM_TIME)
                if (confirmTimeSection !== undefined && confirmTimeSection.selectedData.hasOwnProperty('reservationLength')) {
                    let reservationLength = confirmTimeSection.selectedData.reservationLength
                    params['reservation_length'] = reservationLength
                } else {
                    let selectTimeSection = state.newSectionsData.find(section => section.id===SectionTypes.SELECT_TIME)
                    if (selectTimeSection !== undefined) {
                        let reservationLength = selectTimeSection.selectedData.reservationLength
                        params['reservation_length'] = reservationLength
                    }
                }                

                let specialBookingOptionsSection = state.newSectionsData.find(section => section.id===SectionTypes.SELECT_SPECIAL_BOOKING_OPTIONS)
                if (specialBookingOptionsSection !== undefined) {
                    let selectedData = specialBookingOptionsSection.selectedData
                    console.log(selectedData)
                    if (selectedData.hasOwnProperty("data")) {
                        for (let [key, value] of Object.entries(selectedData.data)) {
                            params[key] = value
                        }
                    }
                }
                return apiService.submitReservation(params)
                    .then((data) => {
                        console.log(data)
                        state.isLoading = false
                        state.reservationMessage = data
                        const result = contactInformationSection.validateData({data})
                        return result
                    })
                    .catch((err) => { 
                        console.log(err);
                        state.isLoading = false;
                        return {'isValid': false, 'error': err}
                     })
            } 

            function submitReview(state, apiService) {
                state.isLoading = true
                const section = state.newSectionsData.find(section => section.id===SectionTypes.BOOKING_COMPLETE)
                const rating = section.selectedData.rating
                const comment = section.selectedData.comment
                const bookingIdentifierNo = section.selectedData.BookingIdentifierNo 

                return apiService.submitReview({rating, comment, bookingIdentifierNo})
                    .then((data) => {
                        state.isLoading = false
                        const result = section.validateData({data})
                        return result
                    })
                    .catch((err) => {
                        console.log(err)
                        state.isLoading = false
                        return {'isValid': false, 'error': err}
                    })
            }

            const section = this.state.newSectionsData[this.state.currentSectionIndex]
            if (section === undefined) {
                return false
            }

            if (section.id === SectionTypes.BOOKING_INFORMATION) {
                return validateBookingInformationsData(this.state, apiService)
            }else if (section.id === SectionTypes.CONTACT_INFORMATION) {
                return submitBookingInformation(this.state, apiService)
            } else if (section.id === SectionTypes.BOOKING_COMPLETE) {
                return submitReview(this.state, apiService)
            }else {
                return section.validateData()
            }
        }
    },

    modules: {
        // We can move all above logic to a separate module, to keep it cleaner
    }
});