<template>
    <div>
        <h3 
            role="tab"
            class="ui-accordion-header ui-helper-reset ui-state-default ui-accordion-icons"
            :class="sectionHeaderClasses"
            @mouseover="handleMouseOver"
            @mouseleave="handleMouseLeave"
            @click="$emit('onSectionClicked')"
        >
            <span class="ui-accordion-header-icon ui-icon" :class="iconName"></span>
            <span :style="{opacity:titleOpacity}" v-html="titleOrEmpty"></span>
        </h3>
        <div v-if="isExpanded" 
            class=""
            :class="sectionContentIsActiveClasses"
            style="display: block;"
            role="tabpanel"
            aria-hidden="false"
        >
            <SelectConcept 
                v-if="type == sectionTypes.SELECT_CONCEPT"
                :data="data"
                :dataSelected="dataSelected"
                :restaurantData="restaurantData"  
            />
            <SelectNumberOfPeople 
                v-else-if="type == sectionTypes.SELECT_PEOPLE"
                :data="data"
                :dataSelected="dataSelected"
                :restaurantData="restaurantData"  
            />
            <SelectNumberOfChildren 
                v-else-if="type == sectionTypes.SELECT_CHILDREN"
                :data="data"
                :dataSelected="dataSelected"
                :restaurantData="restaurantData"
            />
            <SelectNumberOfChildrenChairs 
                v-else-if="type == sectionTypes.SELECT_CHILDREN_CHAIRS"
                :data="data"
                :dataSelected="dataSelected"
            />
            <SelectDate
                v-else-if="type == sectionTypes.SELECT_DATE"
                :data="data"
                :dataSelected="dataSelected"
            />
            <ConfirmDate 
                v-else-if="type == sectionTypes.CONFIRM_DATE"
                :data="data"
                :dataSelected="dataSelected"
            />
            <SelectTime
                v-else-if="type == sectionTypes.SELECT_TIME"
                :data="data"
                :restaurantData="restaurantData"
                :dataSelected="dataSelected"
            />
            <ConfirmTime 
                v-else-if="type == sectionTypes.CONFIRM_TIME"
                :data="data"
                :restaurantData="restaurantData"
                :dataSelected="dataSelected"
                />
            <SelectArea 
                v-else-if="type == sectionTypes.SELECT_AREA || type == sectionTypes.SELECT_AREA_AFTER_TIME"
                :data="data"
                :dataSelected="dataSelected"
                :restaurantData="restaurantData"   />
            <ConfirmArea 
                v-else-if="type == sectionTypes.CONFIRM_AREA"
                :data="data"
                :dataSelected="dataSelected"
            />
            <SelectSpecialBookingOptions 
                v-else-if="type == sectionTypes.SELECT_SPECIAL_BOOKING_OPTIONS"
                :data="data"
                :dataSelected="dataSelected"
            />
            <BookingComplete
                v-else-if="type == sectionTypes.BOOKING_COMPLETE"
                :data="data"
                :dataSelected="dataSelected"
                :error="error"
            />
            <ContactInformation 
                v-else-if="type == sectionTypes.CONTACT_INFORMATION"
                :restaurantID="restaurantID"
                :dataSelected="dataSelected"
                :restaurantData="restaurantData"
                :error="error"
            />
            <BookingInformation 
                v-else-if="type == sectionTypes.BOOKING_INFORMATION"
                :restaurantData="restaurantData"
                :dataSelected="dataSelected"
                :restaurantID="restaurantID"
                :error="error"
            />
            <CancellationCompleted 
                v-else-if="type == sectionTypes.CANCELLATION_COMPLETED"
                :restaurantID="restaurantID"
            />
            <p v-else>Something is wrong {{ type }}</p>
        </div>
    </div>
</template>

<script lang="js">

import SectionTypes from '../models/SectionTypesEnum.js';

import SelectConcept from './section_types/SelectConcept.vue'
import SelectNumberOfPeople from './section_types/SelectNumberOfPeople.vue'
import SelectNumberOfChildren from './section_types/SelectNumberOfChildren.vue'
import SelectNumberOfChildrenChairs from './section_types/SelectNumberOfChildrenChairs.vue'
import SelectDate from './section_types/SelectDate.vue'
import ConfirmDate from './section_types/ConfirmDate.vue'
import SelectTime from './section_types/SelectTime.vue'
import ConfirmTime from './section_types/ConfirmTime.vue'
import SelectArea from './section_types/SelectArea.vue'
import SelectSpecialBookingOptions from './section_types/SelectSpecialBookingOptions.vue'
import BookingComplete from './section_types/BookingComplete.vue';
import ConfirmArea from './section_types/ConfirmArea.vue'
import ContactInformation from './section_types/ContactInformation.vue'

import BookingInformation from './section_types/BookingInformation.vue'
import CancellationCompleted from './section_types/CancellationCompleted.vue'

export default {
    name: 'Section',
    data() {
        return {
            sectionTypes: SectionTypes,
            sectionHeaderClasses: '',
            titleOpacity: 1
        }
    },
    components: {
        "SelectConcept": SelectConcept,
        "SelectNumberOfPeople": SelectNumberOfPeople,
        "SelectNumberOfChildren": SelectNumberOfChildren,
        "SelectNumberOfChildrenChairs": SelectNumberOfChildrenChairs,
        "SelectDate": SelectDate,
        "ConfirmDate": ConfirmDate,
        "SelectTime": SelectTime,
        "ConfirmTime": ConfirmTime,
        "SelectArea": SelectArea,
        "SelectSpecialBookingOptions": SelectSpecialBookingOptions,
        "ConfirmArea": ConfirmArea,
        "ContactInformation": ContactInformation,
        "BookingComplete": BookingComplete,
        "BookingInformation": BookingInformation,
        "CancellationCompleted": CancellationCompleted
    },
    props: ['title', 'isLocked', 'isExpanded', 'type', 'data', 'restaurantID', 'restaurantData', 'onSectionClicked', 'onDataSelected', 'error'],
    computed: {
        titleOrEmpty() {
            if (this.title && this.title.length > 0) {
                this.titleOpacity = 1
                return this.title
            }else {
                this.titleOpacity = 0
                return 'Empty'
            }
        },
        isActiveClasses() {
            this.sectionHeaderClasses = this.isExpanded ? 'ui-accordion-header-active ui-state-active ui-corner-top' : 'ui-corner-all'
            return this.sectionHeaderClasses
        },
        sectionContentIsActiveClasses() {
            return this.isExpanded ? 'ui-accordion-content ui-helper-reset ui-widget-content ui-corner-bottom ui-accordion-content-active' : ''
        },
        iconName() {
            if (this.isLocked) {
                return 'ui-icon-locked'
            }else if (this.isExpanded) {
                return 'ui-icon-pencil'
            }else {
                return 'ui-icon-check'
            }
        },
        lang() {
            return this.$store.state.lang
        }
    },
    methods: {
        dataSelected(data) {
            this.onDataSelected(this.type, data)
        },
        handleMouseOver() {
            this.sectionHeaderClasses = this.isActiveClasses + ' ui-state-hover'
        },
        handleMouseLeave() {
            this.sectionHeaderClasses = this.isActiveClasses
        },
    }
}
</script>


<style>
    .icon {
        color: rgb(232, 230, 227);
        margin-right: 8px;
    }
</style>